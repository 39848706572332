import React from "react";
import { mapping, DropDownListButton, FormatDate } from "@aim-mf/styleguide";
import { TaskCard } from "./taskCard";

// Properties
//// Title
//// Filter list 1
//// Filter list 2
//// InboxData
//// PendingData

class TaskPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let taskDataShown = this.props.taskDataShown;
    // console.log(taskDataShown)
    taskDataShown =
      this.props.filterValue1 === "Alert"
        ? taskDataShown.filter((task) => task.type.includes("alert"))
        : this.props.filterValue1 === "Task"
        ? taskDataShown.filter((task) => task.type.includes("task"))
        : this.props.taskDataShown;
    taskDataShown =
      this.props.filterValue2 === "Creation Date"
        ? taskDataShown.sort((a, b) =>
            FormatDate(a.date, "YYYYMMDD") > FormatDate(b.date, "YYYYMMDD")
              ? 1
              : -1
          )
        : taskDataShown.sort((a, b) =>
            FormatDate(a.dueDate, "YYYYMMDD") >
            FormatDate(b.dueDate, "YYYYMMDD")
              ? 1
              : -1
          );
    return (
      <div
        style={{
          ...PanelStyle,
          width: this.props.viewHistory ? "96.5rem" : "47.75rem",
        }}
      >
        {/* HEADER */}
        <div style={{ flexDirection: "column" }}>
          <div
            style={{
              ...SectionStyle,
              width: this.props.viewHistory ? "94.5rem" : "45.75rem",
            }}
          >
            <div style={{ ...mapping["heading/h5/lightleft"], float: "left" }}>
              {this.props.title}
            </div>
            <div style={taskCountStyle}>{this.props.taskDataShown.length}</div>
          </div>
          {/* SEARCH & FILTER */}
          <div
            style={{
              ...SectionStyle,
              marginBottom: "1.5rem",
              width: this.props.viewHistory ? "94.5rem" : "45.75rem",
            }}
          >
            <div style={{ float: "left" }}>
              <SearchBar
                onCustomChange={this.props.handleSearch}
                width={this.props.viewHistory ? "74.75rem" : "26rem"}
              />
            </div>
            <div style={{ float: "left", marginLeft: "1.25rem" }}>
              <DropDownListButton
                value={this.props.filterValue1}
                data={this.props.filterList1}
                onChange={this.props.handleFilter1}
              />
            </div>
            <div style={{ float: "left", marginLeft: "1rem" }}>
              <DropDownListButton
                value={this.props.filterValue2}
                data={this.props.filterList2}
                onChange={this.props.handleFilter2}
              />
            </div>
          </div>
        </div>
        {/* TASK CARDS */}
        <CheckTaskCards
          taskDataShown={this.props.taskDataShown}
          taskData={this.props.taskData}
        />

        <div
          className={"scrollbar"}
          style={{
            overflowY: "scroll",
            float: "left",
            height: "700px",
            width: this.props.viewHistory ? "96rem" : "46.25rem",
          }}
        >
          {taskDataShown
            .sort((a, b) => {
              return a.id > b.id ? 1 : -1;
            })
            .map((task, index) => {
              return (
                <div
                  style={{
                    marginLeft:
                      index % 2 !== 0 && this.props.viewHistory
                        ? "48.75rem"
                        : "0rem",
                  }}
                >
                  <TaskCard
                    userList={this.props.userList}
                    taskCardData={task}
                    handleViewTask={this.props.handleViewTask}
                    viewHistory={this.props.viewHistory}
                    // handleViewTaskClose={this.props.handleViewTaskClose}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
const PanelStyle = {
  float: "left",
  backgroundColor: "#2F3B52",
  height: "840px",
  width: "47.75rem",
  borderRadius: "10px",
  margin: "0.5rem",
  padding: "1rem",
};
const SectionStyle = {
  // float: "left",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "35px",
  width: "45.75rem",
  marginBottom: "1rem",
};
const taskCountStyle = {
  float: "right",
  height: "24px",
  width: "24px",
  color: "white",
  fontFamily: "Roboto",
  fontSize: "14px",
  letterSpacing: "0",
  textAlign: "center",
  borderColor: "#9EB7C5",
  borderWidth: "1px",
  borderRadius: "2px",
};
const InputFieldStyle = {
  height: "32px",
  width: "26rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  color: mapping["Color_Basic_Light"],
};
const SearchBar = (props) => {
  return (
    <input
      name={(event) => {
        event.target.value;
      }}
      placeholder={"Search by keywords"}
      style={Object.assign({}, mapping["forms/input-default"], {
        ...InputFieldStyle,
        width: props.width ? props.width : "26rem",
      })}
      onChange={(event) => {
        props.onCustomChange(event.target.value);
      }}
    />
  );
};
const CheckTaskCards = (props) => {
  let taskDataShown = props.taskDataShown;
  let taskData = props.taskData;
  if (taskData.length === 0) {
    return (
      <div
        style={{
          ...mapping["paragraph/default/lightcenter"],
          marginTop: "340px",
        }}
      >
        You have no new tasks
      </div>
    );
  } else if (taskData.length !== 0 && taskDataShown.length === 0) {
    return (
      <div
        style={{
          ...mapping["paragraph/default/lightcenter"],
          marginTop: "340px",
        }}
      >
        No results found
      </div>
    );
  }
  {
    return <div> </div>;
  }
};

// section header (inbox/pending) mapping["heading/h5/lightleft"]
// card header mapping["heading/h5/lightleft"]
// card sub header mapping["paragraph/default/lightleft"]

// card content header:
// sectionTitleStyle: {
// ...mapping["Sub Text Tag/H7-blue-tag"],
//       color: "#00BEFF",
// },

// card content mapping["paragraph/default/lightleft"]

// const InputFieldStyle = {
//   height: "32px",
//   width: "26.5rem",
//   marginTop: "-15px",
//   borderWidth: "0px",
//   backgroundColor: mapping["Color_Form_00_Field_Solid"],
//   border: "none !important",
//   color: mapping["Color_Basic_Light"],
//   padding: "4px",
//   resize: "none",
//   overflow: "hidden",
//   paddingLeft: mapping["Field_padding_Horizontal"],
//   paddingRight: mapping["Field_padding_Horizontal"],
//   borderRadius: 0,
//   outline: "none",
// <FieldInput
//   hint={"Search by keywords"}
//   name={props.value}
//   onChange={(event) => {
//     props.onCustomChange(event.value);
//   }}
// />
// };

const BlurEffectStyle = {
  display: "inline-flex",
  width: "100%",
  filter: "blur(10px)",
};

export { TaskPanel };
