import React from "react";
import { mapping, FindDisplayName } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class TaskCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let taskCardData = this.props.taskCardData;
    let completedTasks = 0;
    //console.log(taskCardData)
    taskCardData.actions.map((action) => {
      if (
        action.actionType === "Text Field" &&
        action.actionContent[0].answer &&
        action.actionContent[0].answer !== ""
      ) {
        completedTasks = completedTasks + 1;
      }
      // else if (action.actionType === "Upload Request" && action.actionContent[0].selected !== "") {
      //     completedTasks = completedTasks+1
      // }
      else if (
        action.actionType === "Multiple Choice" &&
        action.actionContent[0].selected !== undefined &&
        action.actionContent[0].selected.length !== 0
      ) {
        completedTasks = completedTasks + 1;
        // console.log("multiple choice is problem");
      } else if (
        action.actionType === "Upload Request" &&
        action.actionContent[0].attachments !== undefined &&
        action.actionContent[0].attachments.length !== 0
      ) {
        completedTasks = completedTasks + 1;
        // console.log("multiple choice is problem");
      }
    });
    if (taskCardData["type"] === "alert") {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={"div_hover"}
          style={{ ...CardStyle, borderLeftColor: "#DC3545" }}
          onClick={() => {
            navigateToUrl("alert-management/" + taskCardData.id + "/");
          }}
        >
          <CardHeader
            title={taskCardData["title"]}
            date={taskCardData["date"]}
            type="Alert"
          />
          <AlertInfo
            dueDate={taskCardData["dueDate"]}
            status={taskCardData["status"]}
            from={taskCardData["from"]}
          />
        </div>
      );
    } else if (taskCardData["type"] === "task") {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={"div_hover"}
          style={CardStyle}
          onClick={() => {
            this.props.handleViewTask(taskCardData);
            //   this.seeActions(taskCardData.actions)
          }}
        >
          <CardHeader
            title={taskCardData["title"]}
            date={taskCardData["date"]}
            type={"Assigned Task"}
          />
          <TaskInfo
            category={taskCardData["category"]}
            dueDate={taskCardData["dueDate"]}
            status={taskCardData["status"]}
            value={
              taskCardData["category"] === "pending"
                ? FindDisplayName(this.props.userList, taskCardData["to"][0])
                : FindDisplayName(this.props.userList, taskCardData["from"])
            }
            completedTasks={completedTasks}
            totalTasks={taskCardData.actions.length}
          />
        </div>
      );
    }
  }
  // seeActions = (actions) => {
  //     // eslint-disable-next-line no-console
  //     console.log(actions);
  //     actions.map((action) => {
  //         if (
  //             action.actionType === "Text Field" &&
  //             action.actionContent[0].answer &&
  //             action.actionContent[0].answer !== ""
  //         ) {
  //             // eslint-disable-next-line no-console
  //             console.log("Not empty text: ", action)
  //         }
  //             // else if (action.actionType === "Upload Request" && action.actionContent[0].selected !== "") {
  //             //     completedTasks = completedTasks+1
  //         // }
  //         else if (
  //             action.actionType === "Multiple Choice" &&
  //             action.actionContent[0].selected.length !== 0
  //         ) {
  //             console.log("Not empty multiple choice: ", action)
  //             console.log("see this: ", action.actionContent[0].selected)
  //             console.log("see length: ", action.actionContent[0].selected.length)
  //             console.log("empty?: ", action.actionContent[0].selected !== [])
  //         }
  //     });
  // }
}

const CardStyle = {
  float: "left",
  backgroundColor: "#1D2739",
  height: "158px",
  width: "734px",
  borderRadius: "5px",
  borderLeftWidth: "1rem",
  borderLeftColor: "#00A2FF",
  marginBottom: "10px",
  padding: "20px 20px 20px 39px",
};
const CardHeader = (props) => {
  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <div>
        <div style={{ ...mapping["heading/h5/lightleft"], float: "left" }}>
          {props.title}
        </div>
        <div
          style={{ ...mapping["paragraph/small/lightright"], float: "right" }}
        >
          {props.date}
        </div>
      </div>
      <div style={{ clear: "both", paddingTop: "0.5rem" }}>
        <div
          style={{
            ...mapping["paragraph/small/lightleft"],
            color: "#818181",
          }}
        >
          {props.type}
        </div>
      </div>
    </div>
  );
};
const AlertInfo = (props) => {
  return (
    <div>
      <InfoColumn width="8rem" title="DUE DATE" value={props.dueDate} />
      <InfoColumn width="10rem" title="STATUS" value={props.status} />
      <InfoColumn width="14rem" title="FROM" value={props.from} />
      <div style={{ float: "right", marginTop: "1rem" }}>
        {/*ALERT ICONS HERE*/}{" "}
      </div>
    </div>
  );
};
const TaskInfo = (props) => {
  return (
    <div>
      <InfoColumn width="8rem" title="DUE DATE" value={props.dueDate} />
      <InfoColumn width="10rem" title="STATUS" value={props.status} />
      <InfoColumn
        width="12rem"
        title={props.category === "pending" ? "TO" : "FROM"}
        value={props.value}
      />
      <InfoColumn
        width="auto"
        title="TASKS COMPLETED"
        float="right"
        value={props.completedTasks + " of " + props.totalTasks}
      />
    </div>
  );
};
const InfoColumn = (props) => {
  return (
    <div
      style={{
        width: props.width,
        float: props.float ? props.float : "left",
        align: props.align ? props.align : "left",
      }}
    >
      <div
        style={{
          ...mapping["Sub Text Tag/H7-blue-tag"],
          color: "#00BEFF",
          marginBottom: "0.5rem",
          clear: "both",
        }}
      >
        {props.title}
      </div>
      <div style={mapping["paragraph/default/lightleft"]}>{props.value}</div>
    </div>
  );
};

// section header (inbox/pending) mapping["heading/h5/lightleft"]
// card header mapping["heading/h5/lightleft"]
// card sub header mapping["paragraph/default/lightleft"]   // USE FOR DATA

// card content header:
// sectionTitleStyle: {
// ...mapping["Sub Text Tag/H7-blue-tag"],
//       color: "#00BEFF",
// },

// card content mapping["paragraph/default/lightleft"]

const BlurEffectStyle = {
  display: "inline-flex",
  width: "100%",
  filter: "blur(10px)",
};

export { TaskCard };
